import React, { Component } from "react";
import {
  Typography,
  Paper,
  Tabs as Tabs1,
  Tab as Tab1,
} from "@material-ui/core";
import { Tabs, Tab } from "react-bootstrap";
import { GetData } from "../../api/service";
import AcademicAbout from "./AcademicAbout";
import CollegeNotificationShort from "../College/CollegeNotificationShort";

const smallTab = [
  {
    title: "Course",
    active: 0,
  },
  {
    title: "Academic calender",
    active: 1,
  },
  {
    title: "Evalution System",
    active: 2,
  },
  {
    title: "Result",
    active: 3,
  },
  {
    title: "Admission Procedure",
    active: 4,
  },
];

class AcademicHomeLanding extends Component {
  state = {
    active: 0,
    dept_code: "PDUAM",

    data: {},
    isDeptLoaded: false,
  };

  __getDeptData = (dept_code) => {
    GetData(`/admin/${dept_code}/getsingledeptdata`).then((resp) => {
      //console.log(resp)
      if (resp != false) {
        this.setState({
          data: resp,
          active: 0,
          isDeptLoaded: true,
        });
      } else {
        this.setState({
          data: {},
          active: 0,
          isDeptLoaded: true,
        });
      }
    });
  };

  componentDidMount() {
    this.__getDeptData(this.state.dept_code);
  }

  render() {
    return (
      <div>
        <Typography variant="h6">Academics</Typography>
        <Typography variant="subtitle2">
          Code: {this.state.dept_code}
        </Typography>

        <br />

        {this.state.isDeptLoaded && (
          <Paper square>
            <Tabs1
              value={this.state.active}
              indicatorColor="primary"
              textColor="primary"
              onChange={(e, newValue) =>
                this.setState({
                  active: newValue,
                })
              }
              aria-label=""
            >
              {smallTab.map((el, index) => (
                <Tab1 value={el.active} key={el.active} label={el.title} />
              ))}
            </Tabs1>
          </Paper>
        )}
        <br />
        {this.state.active == 0 ? (
          <AcademicAbout type={`ADMISSION_COURSE`} title="Add Course" />
        ) : null}

        {this.state.active == 1 ? (
          
          <CollegeNotificationShort
            type={`ACADEMIC_CALENDAR`}
            title="Add Academic Calendar"
          />
        ) : null}

        {this.state.active == 2 ? (
          <AcademicAbout
            type={`EVALUATION_SYSTEM`}
            title="Add Evalution System"
          />
        ) : null}

        {this.state.active == 3 ? (
          <CollegeNotificationShort
            type={`EXAM_RESULTS`}
            title="Add Result detalis"
          />
        ) : null}

        {this.state.active == 4 ? (
          <AcademicAbout type={`ADMISSION_PROCEDURE`} title="Admission Procedure" />
        ) : null}
      </div>
    );
  }
}

export default AcademicHomeLanding;
