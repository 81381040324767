import React, { Component } from "react";
import {
  withStyles,
  CardHeader,
  Avatar,
  Typography,
  CardContent,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  CardActions,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Chip,
} from "@material-ui/core";
import Add from "@material-ui/icons/Add";
import { DeleteData, GetData, PostData, PutData } from "../../../api/service";
import Snack from "../../Snackbar/Snack";
import FileUpload from "../../AddNew/FileUpload";
import Rich from "../../AddNew/Rich";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
import { Card } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
const styles = (theme) => ({
  textField: {
    width: "100%",
  },
  formControl: {
    width: "100%",
    marginTop: theme.spacing.unit * 2,
  },
});

// Create a new Date object
var currentDate = new Date();

// Extract year, month, and day
var year = currentDate.getFullYear();
var month = ("0" + (currentDate.getMonth() + 1)).slice(-2); // Months are zero-based
var day = ("0" + currentDate.getDate()).slice(-2);

// Concatenate year, month, and day with '-' as separator
var formattedDate = year + "-" + month + "-" + day;

class IqacAbout extends Component {
  state = {
    date: formattedDate,
    posted_as: "IQAC Coordinator",
    type: "IQAC_ABOUT",
    title: "",
    description: "",
    upload_info: [],
    featured_photo: [],
    opening_date: "",
    dept_code: "PDUAM",
    closing_date: "",
    data: [],
    isLoaded: false,
    open: false,
    edit: false,
    loadFile: true,
    message: "",
  };

  __getDeptData = () => {
    let type = "";

    type = "NONE";

    GetData(`/PDUAM/${type}/getnoticesbytypedept`).then((resp) => {
      // filter those
      let dd = ["IQAC_ABOUT"];

      let filtredData = resp.filter((el) => dd.includes(el.type));

      if (filtredData.length == 1) {
        this.setState({
          ...filtredData[0],
          edit: true,
        });
      }
      this.setState({
        data: filtredData,
        isDataLoaded: true,
      });
    });
  };

  __getInitialState() {
    this.setState(
      {
        date: formattedDate,
        posted_as: "IQAC Coordinator",
        type: "IQAC_ABOUT",
        title: "",
        description: "",
        upload_info: [],
        featured_photo: [],
        opening_date: "",
        dept_code: "PDUAM",
        closing_date: "",
        edit: false,
        loadFile: false,
      },
      () => {
        this.setState({
          loadFile: true,
        });
      }
    );
  }

  __loadType() {}

  componentDidMount() {
    this.__loadType();
    this.__getDeptData();
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.__loadType();
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleChangeName = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  handleRichChange = (data) => {
    this.setState({
      description: data,
    });
  };

  onSetFile = (data) => {
    //console.log(data)
    this.setState({
      uploadInfo: data,
    });
  };

  onSetFileFeatured = (data) => {
    //console.log(data)
    this.setState({
      featured_photo: data,
    });
  };

  onSubmit = (e) => {
    e.preventDefault();

    let data = {
      ...this.state,
    };

    //console.log(data);

    if (this.state.edit) {
      PutData(`/admin/${this.state.id}/editnotices`, data).then((resp) => {
        //console.log(resp)
        this.setState(
          {
            message: resp,
            open: true,
          },
          () => {
            this.__getInitialState();
            this.__getDeptData();
          }
        );
      });
    } else {
      PostData(`/${this.props.apikey}/createnotice`, data)
        .then((resp) => {
          //console.log(resp)
          this.setState({
            open: true,
            message: resp,
          });
        })
        .then(() => {
          this.__getInitialState();
          this.__getDeptData();
        });
    }
  };

  onDeleteClick = (el) => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: this.onDeleteConfirm.bind(this, el.id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  onDeleteConfirm = (id) => {
    DeleteData(`/${this.props.apikey}/${id}/deletenotice`).then((resp) => {
      this.setState(
        {
          message: resp,
          open: true,
        },
        () => this.__getDeptData()
      );
    });
  };

  onEditClick = (el) => {
    this.setState(
      {
        ...el,
        edit: true,
        loadFile: false,
      },
      () => {
        this.setState({
          loadFile: true,
        });
      }
    );
  };

  render() {
    let { classes } = this.props;

    let i = (
      <Grid key={0} item lg={4} sm={12}>
        <TextField
          label="Opening Date"
          className={classes.textField}
          type="date"
          margin="normal"
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
          name="opening_date"
          value={this.state.opening_date}
          onChange={this.handleChange}
        />
      </Grid>
    );

    let j = (
      <Grid key={1} item lg={4} sm={12}>
        <TextField
          label="Closing Date"
          className={classes.textField}
          type="date"
          margin="normal"
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
          name="closing_date"
          value={this.state.closing_date}
          onChange={this.handleChange}
        />
      </Grid>
    );
    return (
      <div>
        {this.state.open ? (
          <Snack
            open={this.state.open}
            handleClose={() => {
              this.setState({
                open: false,
                message: "",
              });
            }}
            message={this.state.message}
          />
        ) : null}
        <form onSubmit={this.onSubmit}>
          <Card>
            <CardHeader
              avatar={
                <Avatar>
                  <Add />
                </Avatar>
              }
              title={
                <Typography variant="h6">
                  Add About section of IQAC Page
                  {/* {this.props.title} */}
                </Typography>
              }
            />
            <CardContent>
              <Grid container spacing={2}>
                <Grid item lg={12} sm={12}>
                  <label>Description</label>
                  <ReactQuill
                    placeholder="Type something here"
                    value={this.state.description}
                    onChange={this.handleChangeName.bind(this, "description")}
                  />
                </Grid>
              </Grid>
            </CardContent>

            <CardActions>
              {this.state.edit ? (
                <div align="right">
                  <Button size="sm" variant="danger" type="submit">
                    Update
                  </Button>
                  &nbsp;
                  <Button
                    size="sm"
                    variant="danger"
                    type="button"
                    onClick={() => this.__getInitialState()}
                  >
                    Cancel
                  </Button>
                </div>
              ) : (
                <div align="right">
                  <Button size="sm" variant="info" type="submit">
                    Submit
                  </Button>
                </div>
              )}
            </CardActions>
          </Card>
        </form>

        <br />
      </div>
    );
  }
}

export default withStyles(styles)(IqacAbout);
