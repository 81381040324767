import React, { Component } from 'react'
import { withStyles, Card, CardHeader, Avatar, Typography, CardContent, Grid, TextField, FormControl, InputLabel, Select, OutlinedInput, MenuItem, CardActions, Button } from '@material-ui/core';
import Add from '@material-ui/icons/Add'
import Rich from './Rich';
import FileUpload from './FileUpload';
import { PostData, DeleteData, PutData } from '../../api/service';
import Snack from '../Snackbar/Snack';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css

const styles = theme => ({
    textField: {
        width: '100%'
    },
    formControl: {
        width: '100%',
        marginTop: theme.spacing.unit * 2
    }
})

class EditNewContainer extends Component {

    state = {
        date: '',
        type: this.props.code,
        posted_as: '',
        title: '',
        description: '',
        uploadInfo: [],
        opening_date: '',
        closing_date: '',
        dept_code: 'PDUAM',
        open: false,
        message: '',
        id: '',
        desc: '',
        edit: false
    }

    __loadData(){
        let data = this.props.history.location.state;
        console.log(data)
        this.setState({
            ...data,
            desc: data.description,
            edit: true,
            type: data.type,
            code: data.type,
            uploadInfo: data.upload_info,
        })
    }

    componentDidMount() {
        // load edit data

        this.__loadData()
    }

    componentDidUpdate(prevProps){
        if(this.props !== prevProps){
            this.__loadData()
        }
    }


    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleRichChange = (data) => {
        this.setState({
            description: data
        })
    }

    onSetFile = (data) => {
        //console.log(data)
        this.setState({
            uploadInfo: data
        })
    }

    onSubmit = (e) => {
        e.preventDefault();

        let data = {
            ...this.state
        }

        //console.log(data);


        PutData(`/${this.props.apikey}/${this.state.id}/editnotices`, data)
            .then((resp) => {
                //console.log(resp)
                this.setState({
                    open: true,
                    message: resp
                })
            }).then(() => {
                setTimeout(() => this.props.history.goBack(), 2000)
            })

    }

    deleteNotice = () => {
        let id = this.state.id;

        confirmAlert({
            title: 'Confirm to delete',
            message: 'Are you sure to do this.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => this.confirmDeleteNotice(id)
                },
                {
                    label: 'No'
                }
            ]
        })

    }

    confirmDeleteNotice = (id) => {
        DeleteData(`/${this.props.apikey}/${id}/deletenotice`)
        .then((resp) => {
            console.log(resp)
            this.setState({
                open: true,
                message: resp
            })
        }).then(() => {
            this.props.history.replace('/view')
        })
    }

    render() {
        let { classes } = this.props;

        let i = <Grid item lg={4} sm={12}>
            <TextField
                label="Opening Date"
                className={classes.textField}
                type="date"
                margin="normal"
                variant="outlined"
                InputLabelProps={{
                    shrink: true
                }}

                name="opening_date"
                value={this.state.opening_date}
                onChange={this.handleChange}
            />
        </Grid>;

        let j = <Grid item lg={4} sm={12}>
            <TextField
                label="Closing Date"
                className={classes.textField}
                type="date"
                margin="normal"
                variant="outlined"
                InputLabelProps={{
                    shrink: true
                }}

                name="closing_date"
                value={this.state.closing_date}
                onChange={this.handleChange}
            />
        </Grid>
        return (
            <div>

                {this.state.open ?
                    <Snack
                        open={this.state.open}
                        handleClose={() => {
                            this.setState({
                                open: false,
                                message: ''
                            })
                        }}
                        message={this.state.message}
                    />

                    : null}
                <form onSubmit={this.onSubmit}>
                    <Card>
                        <CardHeader
                            avatar={
                                <Avatar>
                                    <Add />
                                </Avatar>
                            }
                            title={
                                <Typography
                                    variant="h6"
                                >
                                    Edit {this.state.code}
                                </Typography>
                            }
                        />
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item lg={4} sm={12}>
                                    <TextField
                                        label="Date"
                                        className={classes.textField}
                                        type="date"
                                        margin="normal"
                                        variant="outlined"
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        required={true}
                                        name="date"
                                        value={this.state.date}
                                        onChange={this.handleChange}
                                    />
                                </Grid>

                                <Grid item lg={8} sm={12}>
                                    <FormControl variant="outlined" className={classes.formControl}>
                                        <InputLabel >
                                            Post As
                    </InputLabel>
                                        <Select
                                            value={this.state.posted_as}
                                            onChange={this.handleChange}
                                            input={
                                                <OutlinedInput labelWidth={200} name="posted_as" />
                                            }
                                            required={true}
                                            name="posted_as"

                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            <MenuItem value="Pandit Deendayal Upadhyaya Adarsha Mahavidyalaya">Pandit Deendayal Upadhyaya Adarsha Mahavidyalaya</MenuItem>
                                            <MenuItem value="IQAC Coordinator">IQAC Coordinator</MenuItem>
                      <MenuItem value="Principal">Principal</MenuItem>
                      <MenuItem value="Student's Union">Student's Union</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>

                                {(this.state.code === 'tender' || this.state.code === 'events') ? [i, j] : null}

                                <Grid item lg={12} sm={12}>
                                    <TextField
                                        label="Title"
                                        className={classes.textField}
                                        type="text"
                                        margin="normal"
                                        variant="outlined"
                                        required={true}
                                        name="title"
                                        value={this.state.title}
                                        onChange={this.handleChange}
                                    />
                                </Grid>

                                <Grid item lg={12} sm={12}>
                                    {this.state.edit ? <Rich
                                        placeholder="Description....."
                                        onChange={this.handleRichChange}
                                        edit={this.state.desc}
                                    /> : null}
                                </Grid>

                                <Grid item lg={12} sm={12}>
                                    {this.state.edit ? <FileUpload
                                        dp={this.state.upload_info}
                                        apikey={this.props.apikey}
                                        type={this.props.code}
                                        setDp={this.onSetFile.bind(this)}
                                    /> : null}
                                </Grid>
                            </Grid>
                        </CardContent>

                        <CardActions>
                            <Button
                                variant="outlined"
                                color="primary"
                                type="submit"
                            >
                                Update
            </Button>

                            <Button
                                variant="outlined"
                                color="primary"
                                type="button"
                                onClick={this.deleteNotice}
                            >
                                Delete
            </Button>
                        </CardActions>

                    </Card>

                </form>

            </div>
        )
    }
}

export default withStyles(styles)(EditNewContainer)