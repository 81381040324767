import React, { Component } from 'react';
import '../css/Login.css';
import Notifications, { notify } from 'react-notify-toast';
import AuthService from './AuthService';
import { Link } from 'react-router-dom';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

import FormControl from '@material-ui/core/FormControl';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Fade from '@material-ui/core/Fade';

import LinearProgress from '@material-ui/core/LinearProgress';
import { Typography } from '@material-ui/core';



const styles = theme => ({
    card: {
        maxWidth: 1000
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    actions: {
        display: 'flex',
    },
    expand: {
        transform: 'rotate(0deg)',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
        marginLeft: 'auto',
        [theme.breakpoints.up('sm')]: {
            marginRight: -8,
        },
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    resize: {
        fontSize: 18
    },
    resizeLable: {
        fontSize: 15,
        fontWeight: 400
    }
});

class ByPassLogin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: "",
            password: "",
            isLoading: false
        };
        this.handleChange = this.handleChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.Auth = new AuthService();
    }
    componentDidMount() {
        let search = this.props.history.location.search;
        console.log("🚀 ~ file: ByPassLogin.js:70 ~ ByPassLogin ~ componentDidMount ~ search", search)
        let searchData = search.split("=")
        if(searchData.length > 0){
            let token = searchData[1];
            let decodedTokenStr = atob(token)
            
            let decodedTokenObj = JSON.parse(decodedTokenStr)
            this.Auth.bypasslogin(decodedTokenObj.username, decodedTokenObj.password)
            .then(res => {
                this.setState({
                    username: "",
                    password: "",
                    isLoading: false
                });
                
                //this.props.history.replace('/');
                window.location.reload()
            })
            .catch(err => {
                notify.show('Wrong Username or password', "error", 3000);
                this.setState({
                    isLoading: false
                })
            });
        }
        
    }
    onSubmit(e) {
        e.preventDefault();
        this.setState({
            isLoading: true
        })
        this.Auth.login(this.state.username, this.state.password)
            .then(res => {
                this.setState({
                    username: "",
                    password: "",
                    isLoading: false
                });
                
                //this.props.history.replace('/');
                window.location.reload()
            })
            .catch(err => {
                notify.show('Wrong Username or password', "error", 3000);
                this.setState({
                    isLoading: false
                })
            });
    }
    handleChange(e) {
        this.setState(
            {
                [e.target.name]: e.target.value
            }
        )
    }
    render() {
        const { classes } = this.props;
        return (
            <section className="container_signin">
                <Notifications />

                <Fade in={true} timeout={{ enter: 1500 }}>
                    <section className="login">
                        

                        <div align="center">
                            <header className="welcome-text-login">
                                Pandit Deendayal Upadhyaya Adarsha Mahavidyalaya
                        </header>
                            <h5>Superadmin Login</h5>
                            <br />
                        </div>



                        <Card className={classes.card}>
                            {this.state.isLoading ? <LinearProgress /> : null }
                            <CardContent>

                                <div className="login-contents">
                                <form onSubmit={this.onSubmit}>

                                    <FormControl fullWidth required>
                                        {/* <InputLabel htmlFor="adornment-password">Username</InputLabel> */}
                                        {/* <Input
                                        autoFocus
                                        required
                                        type='text'
                                        name="username"
                                        value={this.state.username}
                                        onChange={this.handleChange}
                                    /> */}

                                        <TextField
                                            required
                                            
                                            label="Email Id"
                                            InputProps
                                            name="username"
                                            value={this.state.username}
                                            onChange={this.handleChange}
                                            margin="normal"
                                            InputProps={{
                                                classes: {
                                                    input: classes.resize,
                                                },
                                            }}
                                            InputLabelProps={{
                                                classes: {
                                                    root: classes.resizeLable
                                                }
                                            }}
                                        />
                                    </FormControl>

                                    <br />






                                    <FormControl fullWidth required>
                                        <TextField
                                            type="password"
                                            required
                                            id="standard-required"
                                            label="Password"
                                            InputProps
                                            name="password"
                                            value={this.state.password}
                                            onChange={this.handleChange}
                                            margin="normal"
                                            InputProps={{
                                                classes: {
                                                    input: classes.resize,
                                                },
                                            }}
                                            InputLabelProps={{
                                                classes: {
                                                    root: classes.resizeLable
                                                }
                                            }}
                                        />
                                    </FormControl>


                                    <br />
                                    <br />

                                    <Button
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        style={{ width: '100%' }}>
                                        Login
                                        </Button>



                                    </form>






                                </div>

                                <div style={{ textAlign: 'center' }}>
                                        <Link to="/forgotpassword">
                                        <Typography>
                                            Forgot password ?
                                    </Typography>
                                    </Link>
                                    </div>
                            </CardContent>
                        </Card>


                    </section>
                </Fade>
            </section>
        );
    }
}

export default withStyles(styles)(ByPassLogin);