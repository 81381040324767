import React, { Component } from "react";
import {
  withStyles,
  CardHeader,
  Avatar,
  Typography,
  CardContent,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  CardActions,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Chip,
} from "@material-ui/core";
import Add from "@material-ui/icons/Add";
import { DeleteData, GetData, PostData, PutData } from "../../../api/service";
import Snack from "../../Snackbar/Snack";
import FileUpload from "../../AddNew/FileUpload";
import Rich from "../../AddNew/Rich";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
import { Card } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css';
const styles = (theme) => ({
  textField: {
    width: "100%",
  },
  formControl: {
    width: "100%",
    marginTop: theme.spacing.unit * 2,
  },
});

class IQACForm extends Component {
  state = {
    date: "",
    type: this.props.code,
    posted_as: "",
    type: "",
    title: "",
    description: "",
    upload_info: [],
    featured_photo: [],
    opening_date: "",
    dept_code: "PDUAM",
    closing_date: "",
    data: [],
    isLoaded: false,
    open: false,
    edit: false,
    loadFile: true,
    message: "",
    iqactype: [
      // {
      //   name: 'IQAC Strategies',
      //   value: 'IQAC_STRATEGIES',
      // },

      // {
      //   name: "Action Plan",
      //   value: "IQAC_ACTION_PLAN",
      // },
      {
        name: "Annual Report",
        value: "IQAC_ANNUAL_REPORT",
      },
      // {
      //   name: "Units & wings",
      //   value: "IQAC_UNITS_AND_WINGS",
      // },
      {
        name: "Minutes & Resolutions",
        value: "IQAC_MIUNTES",
      },
      {
        name: "IQAC Best Practice",
        value: "IQAC_BEST_PRACTICE",
      },
      {
        name: "Academic Calendar",
        value: "IQAC_ACADEMIC_CALENDAR",
      },
      {
        name: "IQAC Core Committees",
        value: "IQAC_CORE_COMMITTEES",
      },
      {
        name: "IQAC Feedback",
        value: "IQAC_FEEDBACK_FORM",
      },
      // {
      //   name: "IQAC Feedback Analysis (Students)",
      //   value: "IQAC_FEEDBACK_ANALYSIS_STUDENTS",
      // },
      // {
      //   name: "IQAC Feedback Analysis (Alumni)",
      //   value: "IQAC_FEEDBACK_ANALYSIS_ALUMNI",
      // },
      // {
      //   name: "IQAC Feedback Analysis (Parents)",
      //   value: "IQAC_FEEDBACK_ANALYSIS_PARENTS",
      // },
      // {
      //   name: "IQAC Feedback Analysis (Teaching Staff)",
      //   value: "IQAC_FEEDBACK_ANALYSIS_TEACHINGSTAFF",
      // },
      // {
      //   name: "IQAC Feedback Analysis (Non Teaching Staff)",
      //   value: "IQAC_FEEDBACK_ANALYSIS_NONTEACHINGSTAFF",
      // },
      // {
      //   name: "IQAC Student Satisfactory Survey",
      //   value: "IQAC_SSS",
      // },
      {
        name: "IQAC Event/News",
        value: "IQAC_EVENT",
      },
      {
        name: "AISHE Data",
        value: "IQAC_AISHE_DATA",
      },
      {
        name: "AISHE Data (Certificate)",
        value: "IQAC_AISHE_DATA_C",
      },
      {
        name: "MoUs & Collaborations",
        value: "IQAC_MOUS_AND_COLLABORATIONS",
      },
      {
        name: "IQAC AQAR",
        value: "IQAC_AQAR",
      },

      // {
      //   name: "Handbook",
      //   value: "IQAC_HANDBOOK",
      // },

      // {
      //   name: "DAC",
      //   value: "IQAC_DAC",
      // },
      // {
      //   name: "IIQA",
      //   value: "IQAC_IIQA",
      // },
      // {
      //   name: "SDF",
      //   value: "IQAC_SDF",
      // },
      {
        name: "SSR",
        value: "IQAC_SSR",
      },
      {
        name: "DVV",
        value: "IQAC_DVV",
      },
      {
        name: "Codes, Guidelines & Circulars",
        value: "IQAC_CGC",
      },
      {
        name: "Audit Report",
        value: "IQAC_AUDIT",
      },

      // {
      //   name: 'IQAC Functions',
      //   value: 'IQAC_FUNCTIONS',
      // },
      // {
      //   name: 'IQAC TEAMS',
      //   value: 'IQAC_TEAMS',
      // },
      // {
      //   name: 'IQAC Self Study Reports',
      //   value: 'IQAC_SSR',
      // },

      // {
      //   name: 'IQAC Action Taken Report',
      //   value: 'IQAC_ATR',
      // },

      {
        name: "Institutional Distinctiveness",
        value: "IQAC_INSTITUTIONAL_DISTINGTIVENESS",
      },

      {
        name: "Organogram",
        value: "IQAC_ORGANOGRAM",
      },
    ],
  };

  __getDeptData = () => {
    let type = "";

    type = "NONE";

    GetData(`/PDUAM/${type}/getnoticesbytypedept`).then((resp) => {
      // filter those
      let dd = this.state.iqactype.map((el) => el.value);
      this.setState({
        data: resp.filter((el) => dd.includes(el.type)),
        isDataLoaded: true,
      });
    });
  };

  __getInitialState() {
    this.setState(
      {
        date: "",
        type: this.props.type,
        posted_as: "",
        type: "",
        title: "",
        description: "",
        upload_info: [],
        featured_photo: [],
        opening_date: "",
        dept_code: "PDUAM",
        closing_date: "",
        edit: false,
        loadFile: false,
      },
      () => {
        this.setState({
          loadFile: true,
        });
      }
    );
  }

  __loadType() {
    this.setState({
      type: this.props.code,
    });
  }

  componentDidMount() {
    this.__loadType();
    this.__getDeptData();
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.__loadType();
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleChangeName = (name, value) => {
    this.setState({
        [name]: value
    })
}

  handleRichChange = (data) => {
    this.setState({
      description: data,
    });
  };

  onSetFile = (data) => {
    //console.log(data)
    this.setState({
      uploadInfo: data,
    });
  };

  onSetFileFeatured = (data) => {
    //console.log(data)
    this.setState({
      featured_photo: data,
    });
  };

  onSubmit = (e) => {
    e.preventDefault();

    let data = {
      ...this.state,
    };

    //console.log(data);

    if (this.state.edit) {
      PutData(`/admin/${this.state.id}/editnotices`, data).then((resp) => {
        //console.log(resp)
        this.setState(
          {
            message: resp,
            open: true,
          },
          () => {
            this.__getInitialState()
            this.__getDeptData()
          }
        );
        
      });
    } else {
      PostData(`/${this.props.apikey}/createnotice`, data)
        .then((resp) => {
          //console.log(resp)
          this.setState({
            open: true,
            message: resp,
          });
        })
        .then(() => {
          this.__getInitialState()
            this.__getDeptData()
        });
    }
  };

  onDeleteClick = (el) => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: this.onDeleteConfirm.bind(this, el.id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  onDeleteConfirm = (id) => {
    DeleteData(`/${this.props.apikey}/${id}/deletenotice`).then((resp) => {
      this.setState(
        {
          message: resp,
          open: true,
        },
        () => this.__getDeptData()
      );
    });
  };

  onEditClick = (el) => {
    this.setState(
      {
        ...el,
        edit: true,
        loadFile: false,
      },
      () => {
        this.setState({
          loadFile: true,
        });
      }
    );
  };

  render() {
    let { classes } = this.props;

    let i = (
      <Grid key={0} item lg={4} sm={12}>
        <TextField
          label="Opening Date"
          className={classes.textField}
          type="date"
          margin="normal"
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
          name="opening_date"
          value={this.state.opening_date}
          onChange={this.handleChange}
        />
      </Grid>
    );

    let j = (
      <Grid key={1} item lg={4} sm={12}>
        <TextField
          label="Closing Date"
          className={classes.textField}
          type="date"
          margin="normal"
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
          name="closing_date"
          value={this.state.closing_date}
          onChange={this.handleChange}
        />
      </Grid>
    );
    return (
      <div>
        {this.state.open ? (
          <Snack
            open={this.state.open}
            handleClose={() => {
              this.setState({
                open: false,
                message: "",
              });
            }}
            message={this.state.message}
          />
        ) : null}
        <form onSubmit={this.onSubmit}>
          <Card>
            <CardHeader
              avatar={
                <Avatar>
                  <Add />
                </Avatar>
              }
              title={
                <Typography variant="h6">
                  Add New IQAC Meterial
                  {/* {this.props.title} */}
                </Typography>
              }
            />
            <CardContent>
              <Grid container spacing={2}>
                <Grid item lg={4} sm={12}>
                  <TextField
                    label="Date"
                    className={classes.textField}
                    type="date"
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    required={true}
                    name="date"
                    value={this.state.date}
                    onChange={this.handleChange}
                  />
                </Grid>

                <Grid item lg={4} sm={12}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel>Post As</InputLabel>
                    <Select
                      value={this.state.posted_as}
                      onChange={this.handleChange}
                      input={
                        <OutlinedInput labelWidth={200} name="posted_as" />
                      }
                      required={true}
                      name="posted_as"
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value="Pandit Deendayal Upadhyaya Adarsha Mahavidyalaya">
                        Pandit Deendayal Upadhyaya Adarsha Mahavidyalaya
                      </MenuItem>
                      <MenuItem value="IQAC Coordinator">
                        IQAC Coordinator
                      </MenuItem>
                      <MenuItem value="Principal">Principal</MenuItem>
                      <MenuItem value="Student's Union">
                        Student's Union
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item lg={4} sm={12}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel>Type</InputLabel>
                    <Select
                      name="type"
                      value={this.state.type}
                      onChange={this.handleChange}
                      input={<OutlinedInput labelWidth={200} name="type" />}
                      required={true}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>

                      {this.state.iqactype.map((el) => (
                        <MenuItem value={el.value}>{el.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                {this.state.type === "COLLEGE_TENDER" ||
                this.state.type === "events"
                  ? [i, j]
                  : null}

                <Grid item lg={12} sm={12}>
                  <TextField
                    label="Title"
                    className={classes.textField}
                    type="text"
                    margin="normal"
                    variant="outlined"
                    required={true}
                    name="title"
                    value={this.state.title}
                    onChange={this.handleChange}
                  />
                </Grid>

                <Grid item lg={12} sm={12}>
                <label>Description</label>
                <ReactQuill
                                        placeholder="Type something here"
                                        value={this.state.description}
                                        onChange={this.handleChangeName.bind(this, 'description')}
                                    />
                </Grid>
                {this.state.type == "IQAC_EVENT" && (
                  <Grid item lg={6} sm={12}>
                    <div>Upload Featured Photo</div>
                    {this.state.type !== "" && (
                      <FileUpload
                        dp={this.state.featured_photo}
                        apikey={this.props.apikey}
                        type={`${this.state.type}_FEATURED`}
                        setDp={this.onSetFileFeatured.bind(this)}
                      />
                    )}
                  </Grid>
                )}

                <Grid item lg={6} sm={12}>
                  <div>Upload Files</div>
                  {this.state.loadFile && this.state.type !== "" && (
                    <FileUpload
                      dp={this.state.upload_info}
                      apikey={this.props.apikey}
                      type={this.state.type}
                      setDp={this.onSetFile.bind(this)}
                    />
                  )}
                </Grid>
              </Grid>
            </CardContent>

            <CardActions>
            {this.state.edit ? (
                                <div align="right">
                                    <Button
                                        size="sm"
                                        variant="danger"
                                        type="submit"
                                    >
                                        Update
                                    </Button>
                                    &nbsp;
                                    <Button
                                        size="sm"
                                        variant="danger"
                                        type="button"
                                        onClick={() => this.__getInitialState()}
                                    >
                                        Cancel
                                    </Button>
                                </div>
                            ) : (<div align="right">
                                <Button
                                    size="sm"
                                    variant="info"
                                    type="submit"
                                >
                                    Submit
                                </Button>
                            </div>)}


             
            </CardActions>
          </Card>
        </form>

        <br />

        <Card>
          <Card.Body>
            {this.state.isDataLoaded ? (
              <div>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>#</TableCell>
                      <TableCell width="30%">Type</TableCell>
                      <TableCell width="30%">Title</TableCell>
                      <TableCell width="20%">Date</TableCell>
                      <TableCell width="20%">File</TableCell>

                      <TableCell>Edit</TableCell>
                      <TableCell>Delete</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.data.map((el, index) => (
                      <TableRow key={index}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{el.type}</TableCell>
                        <TableCell>{el.title}</TableCell>
                        <TableCell>{el.date}</TableCell>

                        <TableCell>
                          {Array.isArray(el.upload_info) &&
                            el.upload_info.map((el1, index1) => (
                              <Chip key={index1} label={el1.path} />
                            ))}
                        </TableCell>

                        <TableCell>
                          <Edit onClick={this.onEditClick.bind(this, el)} />
                        </TableCell>
                        <TableCell>
                          <Delete onClick={this.onDeleteClick.bind(this, el)} />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            ) : (
              <Typography>Loading . . .</Typography>
            )}
          </Card.Body>
        </Card>
      </div>
    );
  }
}

export default withStyles(styles)(IQACForm);
