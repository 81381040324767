export const PHOTOS_LIST = ["BANNER_IMAGES","HIGHLIGHTS", "NCC_HIGHLIGHTS","NSS_HIGHLIGHTS","FACILITY_HIGHLIGHTS","RESEARCH_HIGHLIGHTS"]

export const PHOTOS_LIST_NEW = [
    {
        title: "Banner Images",
        value: "BANNER_IMAGES"
    },
    {
        title: "Student Progression & Job Placements (Achievements)",
        value: "HIGHLIGHTS"
    },
    {
        title: "Research Highlights",
        value: "RESEARCH_HIGHLIGHTS"
    },
    {
        title: "NCC Highlights",
        value: "NCC_HIGHLIGHTS"
    },
    {
        title: "NSS Highlights",
        value: "NSS_HIGHLIGHTS"
    },
    // {
    //     title: "Facilities",
    //     value: "FACILITY_HIGHLIGHTS"
    // }
]